import React, { useState } from "react"

import { Link } from "gatsby"

import "@fortawesome/fontawesome-pro/css/all.css"

const Header = props => {
  const [showMobileNav, setShowMobileNav] = useState(false)

  function toogleMobileNav() {
    if (showMobileNav) {
      setShowMobileNav(false)
    } else {
      setShowMobileNav(true)
    }
  }

  return (
    <div className="top-header-area">
      <header id="header" className="header">
        <div className="navbar top-links__wrapper d-sm-none d-lg-flex">
          <div className="container d-flex justify-content-between top-links__wrapper__container">
            <div className="d-lg-none menuToggler">
              <div className="nav__hamburger_wrap">
                <div
                  className={`nav_hamburger_wrap__inner ${
                    showMobileNav === true ? "toggled" : ""
                  }`}
                >
                  <div
                    className="nav_hamburger_icon btn"
                    onClick={() => setShowMobileNav(!showMobileNav)}
                  >
                    {!showMobileNav ? (
                      <span className="fas fa-bars"></span>
                    ) : (
                      <span className="fas fa-times"></span>
                    )}
                  </div>
                  <div className="nav_hamburger_wrap">
                    <ul className="navigation">
                      <li className="home active">
                        <div className="link-item">
                          <Link to="/">Home</Link>
                        </div>
                      </li>
                      <li>
                        <div className="link-item">
                          <Link to="/vacancies">Job Vacancies</Link>
                        </div>
                      </li>
                      <li>
                        <div className="link-item">
                          <Link to="/job-alerts">Job Alerts</Link>
                        </div>
                      </li>
                      <li>
                        <div className="link-item">
                          <Link to="/vision-and-values">
                            Vision &amp; Values
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="link-item">
                          <Link to="/rewards-and-benefits">
                            Rewards &amp; Benefits
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="link-item">
                          <Link to="/training-and-development">
                            Training &amp; Development
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="link-item">
                          <Link to="/employee-events">Employee Events</Link>
                        </div>
                      </li>
                      <li>
                        <div className="link-item">
                          <Link to="/contact-us">Contact Us</Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <a href="/" className="navbar-brand d-none d-md-flex">
              <img
                loading="lazy"
                src="https://cdn1.accropress.com/Healthspan/logo.svg"
                height="72"
                className="logo"
                alt="Healthspan"
              />
            </a>

            <ul className="d-none d-md-inline-flex mr-auto header__top-links header__top-links--job-vacancies">
              <li>
                <Link to="/vacancies">Job Vacancies</Link>
              </li>
              <li>
                <Link to="/job-alerts/" className="nav-action-sm" role="button">
                  Get job alerts
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="d-lg-inline d-sm-none">
          <div className="container">
            <div className="col-12">
              <ul className="navigation">
                <li className="home active">
                  <div className="link-item">
                    <Link to="/">Home</Link>
                  </div>
                </li>
                <li>
                  <div className="link-item">
                    <Link to="/vision-and-values">Vision &amp; Values</Link>
                  </div>
                </li>
                <li>
                  <div className="link-item">
                    <Link to="/rewards-and-benefits">
                      Rewards &amp; Benefits
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="link-item">
                    <Link to="/training-and-development">
                      Training &amp; Development
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="link-item">
                    <Link to="/employee-events">Employee Events</Link>
                  </div>
                </li>
                <li>
                  <div className="link-item">
                    <Link to="/contact-us">Contact Us</Link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          className="navbar navbar-tablet-mobile top-links__wrapper d-lg-none p-0"
          style={{ minHeight: "64px" }}
        >
          <div className="container d-flex justify-content-between top-links__wrapper__container">
            <div className="d-lg-none menuToggler">
              <div className="nav__hamburger_wrap">
                <div
                  className={`nav_hamburger_wrap__inner ${
                    showMobileNav === true ? "toggled" : ""
                  }`}
                >
                  <div
                    className="nav_hamburger_icon btn"
                    onClick={() => setShowMobileNav(!showMobileNav)}
                  >
                    {!showMobileNav ? (
                      <span className="fas fa-bars"></span>
                    ) : (
                      <span className="fas fa-times"></span>
                    )}
                  </div>
                  <div className="nav_hamburger_wrap">
                    <ul className="navigation">
                      <li className="home active">
                        <div className="link-item">
                          <Link to="/">Home</Link>
                        </div>
                      </li>
                      <li>
                        <div className="link-item">
                          <Link to="/vacancies">Job Vacancies</Link>
                        </div>
                      </li>
                      <li>
                        <div className="link-item">
                          <Link to="/job-alerts">Job Alerts</Link>
                        </div>
                      </li>
                      <li>
                        <div className="link-item">
                          <Link to="/vision-and-values">
                            Vision &amp; Values
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="link-item">
                          <Link to="/rewards-and-benefits">
                            Rewards &amp; Benefits
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="link-item">
                          <Link to="/training-and-development">
                            Training &amp; Development
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="link-item">
                          <Link to="/employee-events">Employee Events</Link>
                        </div>
                      </li>
                      <li>
                        <div className="link-item">
                          <Link to="/contact-us">Contact Us</Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <a href="/" className="mobile-nav-logo">
              <img
                loading="lazy"
                src="https://cdn1.accropress.com/Healthspan/logo.svg"
                className="h-100"
                alt="Healthspan"
              />
            </a>
            <span className="top-text">
              <Link to="/vacancies">Job Vacancies</Link>
            </span>
            <Link
              to="/job-alerts/"
              className="job-alerts-button nav-action-sm nav-action-sm-mobile"
              role="button"
            >
              Get job alerts
            </Link>
          </div>
        </div>
      </header>
    </div>
  )
}

export default Header
