import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useCookies } from "react-cookie"

const Cookies = props => {
  const [cookies, setCookie] = useCookies(["cookie-name"])
  const [active, setActive] = useState(false)

  useEffect(() => {
    if (!cookies.AcceptCookies) {
      setActive(true)
    }
  }, [])

  const onAccept = () => {
    setActive(false)
    setCookie("AcceptCookies", true, {
      path: "/",
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    })
  }

  return (
    <>
      <div
        id="cookieNotification"
        className={`notification${active && " -active"}`}
      >
        <div className="notification__content">
          <h2 className="notification__title">
            We use cookies to help improve user experience.
          </h2>
          <p className="notification__message">
            This includes a range of things such as user personalisation, page
            analytics and more.{" "}
            <a
              target="_blank"
              title="Privacy Policy"
              href="https://www.healthspan.co.uk/privacy-notice"
              className="notification__button -primary"
            >
              Click here to learn more
            </a>
          </p>
          <div className="notification__footer d-flex justify-content-between">
            <button
              id="js-cookie-button-accept"
              className="notification__button -primary nav-action-sm"
              onClick={onAccept}
            >
              Accept
            </button>
            <button
              id="js-cookie-button"
              className="notification__button -secondary nav-action-sm"
              onClick={onAccept}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

Cookies.propTypes = {
  siteTitle: PropTypes.string,
}

Cookies.defaultProps = {
  siteTitle: ``,
}

export default Cookies
